<template>
  <el-drawer
    title="IC Charge"
    :visible.sync="dialogTableVisible"
    v-if="dialogTableVisible"
    direction="btt"
    size="93%"
    custom-class="ChargeOut"
    append-to-body
    :before-close="close"
  >
    <div class="ChargeOut__content">
      <div class="ChargeOut__content__leftStep">
        <el-steps
          direction="vertical"
          :active="activeStep"
          finish-status="success"
        >
          <el-step title="选择HC"></el-step>
          <el-step title="调整"></el-step>
        </el-steps>
      </div>
      <div class="ChargeOut__content__rightComponent">
        <selectHc ref="selectHcRef" :selectHcId="periodId" v-if="activeStep === 0" />
        <Adjustment ref="AdjustmentRef" v-if="activeStep === 1" />
      </div>
    </div>
    <div class="ChargeOut__footer">
      <div v-if="activeStep === 0">
        <!--        :disabled="!disabled"-->
        <el-button @click="next" :loading="nextLoading"     :key="idkey">下一步</el-button>
      </div>
      <div v-if="activeStep === 1">
        <el-button @click="pre" v-if="showButtonCall"     :key="idkey">上一步</el-button>
        <el-button
          type="primary"
          @click="submit"
          v-if="ChargeOut.type !== 'chat'"
          >保存</el-button
        >
      </div>
    </div>
  </el-drawer>
</template>
<script>
import chargeOutPublicData from '@/views/salaryManagement/components/modal/ChargeOutDialog/mixin/chargeOutPublicData'
import selectHc from '@/views/salaryManagement/components/modal/ChargeOutDialog/components/SelectHc.vue'
import Adjustment from '@/views/salaryManagement/components/modal/ChargeOutDialog/components/Adjustment.vue'
import { mapActions, mapState } from 'vuex'
import { saveIcCharge } from '@/api/pctrakerop131'
import { check, typeRange } from '@/utils/util'
export default {
  name: 'ChargeOut',
  mixins: [chargeOutPublicData],
  components: {
    selectHc,
    Adjustment
  },
  data() {
    return {
      dialogTableVisible: false,
      nextLoading: false,
      showButtonCall: true,
      idkey:0,
      id: '',
      periodId: '',
    }
  },
  computed: {
    ...mapState(['ChargeOut']),
    disabled() {
      return Boolean(this.ChargeOut.selectVal.length)
    },
    activeStep: {
      set(val) {
        this.$store.dispatch('ChargeOut/changeActive', val)
      },
      get() {
        return this.ChargeOut.active
      }
    }
  },
  methods: {
    changeDom(){
      this.idkey++
    },
    show(val) {
      if (val) {
        this.$store.dispatch('ChargeOut/changeEditionId', val.editionId)
        this.id = val.id || ''
        if (val && val.periodId) {
          this.periodId = val.periodId || ''
        }

        // this.$store.dispatch('ChargeOut/changeButtonActive', val.buttonActive)
        this.dialogTableVisible = true
        if (val.showButtonCall) {
          this.showButtonCall = false
          this.$nextTick(() => {
            this.$refs.AdjustmentRef.changCall()
          })
        }
      }
    },
    close() {
      /*
          active: 0,
          buttonActive: '',
          editionId: '',
          selectVal: [],
          tableData: []
        */
      this.id= ''
      this.$store.dispatch('ChargeOut/changeEditionId', '')
      // this.$store.dispatch('ChargeOut/changeButtonActive', '')
      this.$store.dispatch('ChargeOut/changeActive', 0)
      this.$store.dispatch('ChargeOut/changeSelectVal', [])
      this.$store.dispatch('ChargeOut/changeTableData', [])
      this.$store.dispatch('ChargeOut/changeChargeType', '')
      this.dialogTableVisible = false
    },
    submit() {
      let data = [];
      this.ChargeOut.tableData.forEach(i => {
        if (i && i != null && i != undefined) {
          data.push(i)
        }
      })
      if (data && check(data) === typeRange.array) {
        data.forEach((item) => {
          item.id = this.id
          item.periodId = this.periodId
        })
      }
      saveIcCharge(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('保存成功')
          this.close()
          this.$emit('close')
        }
      })
    },
    next() {
      this.nextLoading = true
      if (this.ChargeOut.tableData.length) {
        //1. 先找出编辑的数据和选择的数据相同处和不同处。
        let oldDataArr = []
        let newDaraArr = []
        let noHcArr = []
        let selectValData = JSON.parse(JSON.stringify(this.ChargeOut.selectVal))
        let oldTableData = this.ChargeOut.tableData
        //把当前列表中的数据和选择的数据做对比 剔除选择数据里没有的列表数据
        selectValData.forEach((item) => {
          oldTableData.forEach((oldItem) => {
            if (item.id === oldItem.id) {
              oldDataArr.push(oldItem)
            }
          })
        })
        //无hc的筛选出来
        noHcArr = oldTableData.filter((item) => item.noHc)

        //删除旧数据
        for (let index = 0; index < selectValData.length; index++) {
          let oldItem = selectValData[index]
          if (
            oldDataArr.findIndex((indexData) => indexData.id === oldItem.id) <0

          ) {
            newDaraArr.push(oldItem)
          }
        }
        if (newDaraArr.length) {
          this.$store
            .dispatch('ChargeOut/formatTableData', newDaraArr)
            .then((res) => {
              if (res) {
                this.nextLoading = false
                let arr = [...oldDataArr, ...res, ...noHcArr]
                this.$store.dispatch('ChargeOut/changeTableData', arr)
                this.$store.dispatch(
                  'ChargeOut/changeActive',
                  this.activeStep + 1
                )
                this.changeDom()
              }
            })
        } else {
          setImmediate(()=>{
            this.nextLoading = false
          },800)
          oldDataArr = [...noHcArr, ...oldDataArr]

          this.$store.dispatch('ChargeOut/changeTableData', oldDataArr)
          this.$store.dispatch('ChargeOut/changeActive', this.activeStep + 1)
          this.changeDom()
        }
      //   11
      } else {
        this.$store
          .dispatch('ChargeOut/formatTableData', this.ChargeOut.selectVal)
          .then((res) => {
            if (res) {
              this.nextLoading = false
              this.$store.dispatch('ChargeOut/changeTableData', res)
              this.$store.dispatch(
                'ChargeOut/changeActive',
                this.activeStep + 1
              )
              this.changeDom()
            }
          })
      }
    },
    pre() {
      this.$store.dispatch(
        'ChargeOut/changeTableData',
        this.$refs.AdjustmentRef.tableData
      )
      this.$store.dispatch('ChargeOut/changeActive', this.activeStep - 1)
      this.changeDom()
    }
  }
}
</script>

<style lang="scss">
@mixin bodyCenter {
  position: relative;
  width: 1300px;
  left: 50%;
  transform: translateX(-50%);
}
.ChargeOut {
  .el-drawer__header {
    width: 1300px;
    margin-bottom: 20px !important;
    font-size: 21px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #303133;
    @include bodyCenter;
  }
  .el-drawer__body {
    @include bodyCenter;
    overflow: hidden;
  }
  &__content {
    display: flex;
    height: 80vh;
    &__rightComponent {
      overflow: hidden;
    }
  }
  &__footer {
    height: 20vh;
    text-align: right;
  }
  .el-steps {
    &:nth-child(1) {
      .el-step__main {
        .el-step__title {
          position: relative;
          left: -2px;
        }
      }
    }
  }
  .el-steps {
    height: 30%;
    margin-right: 0;
    .el-step__head {
      width: 50px;
      position: relative;
      left: 10px;
    }
    .el-step__main {
      position: relative;
      left: -50px;
      top: 28px;
      .el-step__title {
        background: white;
      }
    }
    .el-step__line {
      width: 1px;
    }
    .el-step__head.is-process {
      .is-text {
        width: 28px;
        height: 28px;
        background: #2490e3;
        color: white;
        border-color: white;
      }
    }
    .el-step__title {
      width: 60px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303133;
    }
  }
}
</style>
