<template>
  <div class="SelectHc">
    <el-form
      :inline="true"
      :model="formInline"
      label-width="100px"
      class="form-inline"
    >
      <el-form-item label="HFM Code">
        <el-select
          v-model="formInline.hfmCode"
          clearable
          filterable
          placeholder="HFM Code"
          @change="getLevel2"
        >
          <el-option
            v-for="item in hfmCodeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Level 2">
        <el-select
          v-model="formInline.level2"
          clearable
          filterable
          placeholder="Level 2"
          @change="getDescriptionLevel3"
        >
          <el-option
            v-for="item in level2List"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Level 3">
        <el-select
          v-model="formInline.level3"
          clearable
          filterable
          placeholder="Level 3"
        >
          <el-option
            v-for="item in level3List"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="BU">
        <el-input
          clearable
          v-model="formInline.bu"
          placeholder="BU"
        ></el-input>
      </el-form-item>
      <el-form-item label="Function">
        <el-input
          clearable
          v-model="formInline.function"
          placeholder="Function"
        ></el-input>
      </el-form-item>
      <el-form-item label="Team">
        <el-input
          clearable
          v-model="formInline.team"
          placeholder="Team"
        ></el-input>
      </el-form-item>
      <el-form-item label="English Name">
        <el-input
          clearable
          v-model="formInline.englishName"
          placeholder="English Name"
        ></el-input>
      </el-form-item>
      <el-form-item style="align-items: end;display: flex;">
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>
    <vxe-table
      :max-height="tableHeight"
      ref="xTable4"
      border="none"
      @checkbox-all="selectAllEvent"
      @checkbox-change="selectChangeEvent"
      stripe
      :loading="loading"
      size="mini"
      :data="tableData"
      show-header-overflow
    >
      <vxe-column type="checkbox" fixed="left"></vxe-column>
      <vxe-column
        v-for="item in columnList"
        :field="item.key"
        :title="item.name"
        :visible="item.visible"
        :minWidth="item.width"
        show-overflow
      >
        <template v-if="item.fold" #header>
          <span>{{ item.name }}</span>
          <i
            style="cursor: pointer"
            :class="
              item.collapsable
                ? 'vxe-icon-square-minus'
                : 'vxe-icon-square-plus'
            "
            @click="item.collapsableEvent(item, $refs.xTable4)"
          ></i>
        </template>
      </vxe-column>
    </vxe-table>
    <vxe-pager
      :loading="loading1"
      size="mini"
      :current-page="tablePage1.pageNum"
      :page-size="tablePage1.pageSize"
      :total="tablePage1.total"
      :layouts="[
        'PrevPage',
        'JumpNumber',
        'NextPage',
        'FullJump',
        'Sizes',
        'Total'
      ]"
      @page-change="handlePageChange1"
    >
    </vxe-pager>
    <div class="maodian">
      <span @click="top">顶部</span>

    </div>
  </div>
</template>
<script>
import {
  changeChargeOutYear,
  chargeOutColumn
} from '@/views/salaryManagement/components/modal/ChargeOutDialog/chargeOutColumn'
import chargeOutPublicData from '@/views/salaryManagement/components/modal/ChargeOutDialog/mixin/chargeOutPublicData'
import { mapState } from 'vuex'
import { getDescriptionLevel3, gethfmCodes, getLevel2, getOp111List, hcList } from "@/api/pctrakerop131";
export default {
  mixins: [chargeOutPublicData],
  name: 'SelectHc',
  created() {
    changeChargeOutYear()
  },
  props: {
    selectHcId: {
      type: String | Number,
      default: ''
    }
  },
  watch: {
    // selectHcId: {
    //   handler(val) {
    //     if (val) {
    //       this.id = val
    //       this.queryApi()
    //     }
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  mounted() {
    this.id = this.$route.query.id
    this.buttonActive = this.$route.query.buttonActive
    this.indexYear = this.$route.query.indexYear
    // let className = document.getElementsByClassName(
    //   'ChargeOut__content__rightComponent'
    // )
    // if (className.length) {
    //   this.tableHeight = className[0].clientHeight - 100
    // }
    this.queryApi()
    this.getOptionsData()
  },
  computed: {
    ...mapState(['ChargeOut'])
  },
  data() {
    return {
      formInline: {
        englishName: '',
        hfmCode: '',
        level2: '',
        level3: '',
        bu: '',
        team: '',
        function: '',
      },
      loading: false,
      tableHeight: 500,
      loading1: false,
      id: '',
      indexYear: '',
      buttonActive: '',
      tablePage1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },

      hcNatureList: [
        {
          label: 'New Hire',
          value: 'New Hire'
        },
        {
          label: 'Replacement',
          value: 'Replacement'
        },
        {
          label: 'Transfer in',
          value: 'Transfer in'
        },
        {
          label: 'Transfer out with HC',
          value: 'Transfer out with HC'
        },
        {
          label: 'HC Delete',
          value: 'HC Delete'
        }
      ],
      tableData: [],
      columnList: chargeOutColumn,
      hfmCodeList:[],
      level2List:[],
      level3List:[],
    }
  },
  methods: {
    top() {
      this.$refs.xTable4.scrollTo(0, 0)
    },
    setCheckboxRowTable() {
      let arr = []
      let tableArr = this.$store.state.ChargeOut.selectVal
      tableArr.forEach((item) => {
        let findItem = this.tableData.find((row) => row.id === item.id)
        if (findItem) {
          if (arr.findIndex((index) => index.id === findItem.id) < 0) {
            arr.push(findItem)
          }
        }
      })
      this.$nextTick(() => {
        this.$refs.xTable4.setCheckboxRow(arr, true)
      })
    },
    selectAllEvent(val) {
      this.$store.dispatch('ChargeOut/changeSelectVal', val.records)
    },
    selectChangeEvent(val) {
      this.$store.dispatch('ChargeOut/changeSelectVal', val.records)
    },
    onSubmit() {
      this.tablePage1.pageNum = 1
      this.queryApi()
    },
    handlePageChange1({ currentPage, pageSize }) {
      this.tablePage1.pageNum = currentPage
      this.tablePage1.pageSize = pageSize
      this.queryApi()
    },
    getOptionsData(){
      gethfmCodes().then((res)=>{
        if(res.code === 200){
          this.hfmCodeList = res.data
        }
      })
      this.getLevel2()
      this.getDescriptionLevel3()
    },
    getLevel2(data){
      if(data){
        this.formInline.level2 = ''
      }
      getLevel2({hfmCode:data}).then((res)=>{
        if(res.code === 200){
          this.level2List = res.data
        }
      })
    },
    getDescriptionLevel3(data){
      if(data){
        this.formInline.level3 = ''
      }
      getDescriptionLevel3({assigmentProjectLevel2:data}).then((res)=>{
        if(res.code === 200){
          this.level3List = res.data
        }
      })
    },
    queryApi() {
      this.loading = true
      const data = {
        id: this.id,
        ...this.formInline,
        ...this.tablePage1,
      }
      hcList(data).then((res) => {
        this.loading = false
        if (res && res.code === 200) {
          this.tableData = res.data.list
          this.tablePage1.total = res.data.total
          this.setCheckboxRowTable()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.SelectHc {
  .form-inline{
    display: flex;
  }
  .maodian{
    cursor: pointer;
  }
}
</style>
<style>
::v-deep .vxe-table--tooltip-wrapper {
  z-index: 1000000 !important;
}
</style>
