import request from '@/utils/request'

export const getDepartments = () => {
  return request({
    url: '/hrRafForm/getDepartmentList',
    method: 'Get'
  })
}
export const getLegalEntity = (params) => {
  return request({
    url: '/hrRafForm/getLegalEntity',
    method: 'Get',
    params: params
  })
}
export const getWorkLocation = (params) => {
  return request({
    url: '/hrRafForm/getWorkLocation',
    method: 'Get',
    params: params
  })
}
export const getLevel2 = (params) => {
  return request({
    url: '/hrRafForm/getLevel2',
    method: 'Get',
    params: params
  })
}
export const addFreelancer = (data) => {
    return request({
      url: '/pctrackerOp151152/addFreelancer',
      method: 'POST',
      params: {
        ...data
      }
    })
  }
