export default {
  data() {
    return {
      active: 0,
      buttonActive: '',
      editionId: ''
    }
  },
  methods: {
    changeButtonActive(val) {
      this.buttonActive = val
    },
    changeEditionId(val) {
      this.editionId = val
    }
  }
}
