<template>
  <div class="addFreeLancherModal">
    <el-dialog
      :visible.sync="dialogVisible"
      width="800px"
      :title="modalTitle"
      @close="close"
      :close-on-click-modal="false"
    >
      <div>
        <el-form>
          <div class="flexBetw">
            <div class="flex-1">
              <el-form-item label="HFM Code" prop="modalName">
                <el-select
                  clearable
                  v-model="selectEditMsg.hfmCode"
                  filterable
                  @change="changeHfmCode"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in hfmCodes"
                    :key="item.label"
                    :label="item.label"
                    :value="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="flex-1 modal-from-item">
              <el-form-item label="Assigment/project(Level2)" prop="Benchmark">
                <el-select
                  clearable
                  v-model="selectEditMsg.assigmentProjectLevel2"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in level2s"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="flex-1 modal-from-item">
              <el-form-item label="Legal Entity" prop="legalEntity">
                <el-select
                  clearable
                  v-model="selectEditMsg.legalEntity"
                  filterable
                  placeholder="请选择"
                  @change="changeLegalEntity"
                >
                  <el-option
                    v-for="item in legalEntitys"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>

          <div class="flexBetw">
            <div class="flex-1">
              <el-form-item label="Department" prop="modalName">
              <el-select
                clearable
                v-model="selectEditMsg.department"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in departments"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
            </div>
            <div class="flex-1 modal-from-item">
              <el-form-item label="Client">
                <el-input placeholder="" v-model="inputMsg.client"> </el-input>
              </el-form-item>
            </div>
            <div class="flex-1 modal-from-item">
              <el-form-item label="Work Location" prop="workLocation">
              <el-select
                clearable
                v-model="selectEditMsg.workLocation"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in workLocations"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
            </div>

          </div>
          <div class="flexBetw">
            <div class="flex-1 ">
              <el-form-item label="CEO" >
                <el-input
                  v-model="selectEditMsg.ceoName"
                  suffix-icon="el-icon-search"
                  @click.native="selectCEO('CEO')"
                  placeholder="请选择"
                  readonly
                />
              </el-form-item>
            </div>
            <div class="flex-1 modal-from-item">
              <el-form-item label="Position">
                <el-input placeholder="" v-model="inputMsg.position">
                </el-input>
              </el-form-item>
            </div>
            <div class="flex-1 modal-from-item">
              <el-form-item label="中文名">
                <el-input placeholder="" v-model="inputMsg.nameCn"> </el-input>
              </el-form-item>
            </div>

          </div>

          <div class="flexBetw">
            <div class="flex-1 ">
              <el-form-item label="英文名">
                <el-input placeholder="" v-model="inputMsg.nameEn"> </el-input>
              </el-form-item>
            </div>
            <div class="flex-1 modal-from-item">
              <el-form-item label="证件号">
                <el-input placeholder="" v-model="inputMsg.idNo"> </el-input>
              </el-form-item>
            </div>
            <div class="flex-1 modal-from-item">
              <el-form-item label="月费用总和">
                <el-input
                  placeholder=""
                  type="number"
                  v-model="inputMsg.totalCostMonthly"
                >
                </el-input>
              </el-form-item>
            </div>

          </div>
          <div class="flexBetw">
            <div class="flex-1 ">
              <el-form-item label="合同开始时间">
                <el-date-picker
                  v-model="inputMsg.contractStartDate"
                  type="date"
                  placeholder=""
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="flex-1 modal-from-item">
              <el-form-item label="合同结束时间">
                <el-date-picker
                  v-model="inputMsg.contractEndDate"
                  type="date"
                  placeholder=""
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="flex-1 modal-from-item"></div>
          </div>
        </el-form>
      </div>
      <div class="flexBetw" style="margin-top: 18px">
        <div></div>
        <div>
          <el-button @click="close">取 消</el-button>
          <el-button type="primary" class="buttonActive" @click="sure"
            >确 定</el-button
          >
        </div>
      </div>
    </el-dialog>
    <personnelSelectionDialog
      ref="personnelSelectionDialogRef"
      @onConfirm="onConfirm"
    />
  </div>
</template>

<script>
import personnelSelectionDialog from "@/components/PersonnelSelectionDialog/PersonnelSelectionDialog.vue";

const { Vuex } = $PCommon

import { gethfmCodes, getLevel2Orgs } from '@/api/pctrakerop131'
import { getDepartments, addFreelancer, getLegalEntity, getWorkLocation,getLevel2 } from "@/api/PctrackerOp151152";

export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: { personnelSelectionDialog },

  props: {
    version: {
      type: Number
    },
    empType: {
      type: Number
    },
    isSummary: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      modalTitle: '',
      BPList: [],
      dialogVisible: true,
      queryLoading: false,
      dateRange: '',
      selectEditMsg: {
        department: '',
        assigmentProjectLevel2: '',
        hfmCode: '',
        ceo:'',
        ceoName:'',
      },
      inputMsg: {
        client: '',
        position: '',
        nameCn: '',
        nameEn: '',
        idNo: '',
        contractStartDate: '',
        contractEndDate: '',
        totalCostMonthly: '',
        type: '',
        versionId: '',
        summaryVersionId: ''
      },
      level2s: [],
      hfmCodes: [],
      departments: [],
      workLocations: [],
      legalEntitys: [],
    }
  },
  computed: {},
  async created() {
    if (this.empType == 0) {
      this.modalTitle = ' Add Contractor'
    } else {
      this.modalTitle = ' Add Freelancer'
    }
    this.inputMsg.versionId = this.version
    this.inputMsg.type = this.empType
    this.selectEditMsg.hfmCode = this.$route.query.hfmCode
    gethfmCodes().then((res) => {
      this.hfmCodes = res.data
    })
    getLevel2({ hfmCode: this.selectEditMsg.hfmCode}).then((res) => {
      this.level2s = res.data
    })
    getDepartments().then((res) => {
      this.departments = res.data
    })
    getLegalEntity({hfmCode: this.selectEditMsg.hfmCode}).then((res) => {
      this.legalEntitys = res.data
    })
    getWorkLocation().then((res) => {
      this.workLocations = res.data
    })
  },
  methods: {
    selectCEO(val) {
      this.dialogStatus = val
      this.$refs.personnelSelectionDialogRef.show({
        type: val,
        position: 'Personnel',
        radio:true
      })
    },
    onConfirm(val) {
      console.log(val)
      if (val && val.length) {
        this.selectEditMsg.ceoName = val[0].userName
        this.selectEditMsg.ceo = val[0].id
      }
    },
    onFilter(type, value) {
      this.filterConfig.periodStart = value[0]
      this.filterConfig.periodEnd = value[1]
    },
    changeHfmCode(val) {
      let data = {
        hfmCode: val
      }
      getLevel2(data).then((res) => {
        this.level2s = res.data
      })
      getLegalEntity({
        hfmCode: val
      }).then((res) => {
        this.legalEntitys = res.data
      })
      if(this.selectEditMsg.hfmCode && this.selectEditMsg.legalEntity){
        getWorkLocation({
          hfmCode:val,
          legalEntity:this.selectEditMsg.legalEntity
        }).then((res) => {
          this.workLocations = res.data
        })
      }else {
        getWorkLocation().then((res) => {
          this.workLocations = res.data
        })
      }

    },
    changeLegalEntity(){
      if(this.selectEditMsg.hfmCode && this.selectEditMsg.legalEntity){
        getWorkLocation({
          hfmCode:this.selectEditMsg.hfmCode,
          legalEntity:this.selectEditMsg.legalEntity
        }).then((res) => {
          this.workLocations = res.data
        })
      }else {
        getWorkLocation().then((res) => {
          this.workLocations = res.data
        })
      }
    },
    async getAssignPeople() {
      this.queryLoading = true
      const { data, code } = await this.$Api.induction.getAssignPeople({
        ...this.selectEditMsg
      })
      if (code === 200) {
        this.tableData = data
        // this.pageConfig.total = data.total
        this.$forceUpdate()
      }
      this.queryLoading = false
    },
    async getBPList() {
      this.queryLoading = true
      const { data, code } = await this.$Api.induction.getHfmCode({})
      if (code === 200) {
        this.BPList = data
      }
      this.queryLoading = false
    },

    async sure() {
      if (!this.selectEditMsg.department) {
        this.$message.error('Department 不能为空')
        return
      }
      if (!this.selectEditMsg.assigmentProjectLevel2) {
        this.$message.error('Assigment/project(Level2) 不能为空')
        return
      }
      if (!this.selectEditMsg.hfmCode) {
        this.$message.error('HFM Code 不能为空')
        return
      }
      if (this.isSummary) {
        this.inputMsg.summaryVersionId = this.inputMsg.versionId
      }
      const data = {
        ...this.selectEditMsg,
        ...this.inputMsg
      }
      if (this.$route.query.buttonActive == '2') {
        if (this.$route.query.changeActive == '2') {
          data.tabOneType = '1'
        } else {
          data.tabOneType = '0'
        }
      } else {
        data.tabOneType = '0'
      }
      addFreelancer(data).then((res) => {
        if (res.code == 200) {
          this.$emit('closeModal')
          this.$emit('getIndexData')
        }
      })
    },
    showMessage(flag) {
      if (flag == 'successFlag') {
        this.$message({
          type: 'success',
          message: '分配成功'
        })
      } else {
        this.$message({
          type: 'error',
          message: '分配失败'
        })
      }
    },
    close() {
      this.dialogVisible = false
      this.$emit('closeModal')
    }
  },
  beforeDestroy() {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
.w100 {
  width: 200px;
}
.modalTitle {
  width: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
  border-bottom: 1px solid #777;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.flexCenter {
  justify-content: center;
  align-items: center;
}
.AlCenter {
  align-items: center;
}
.buttonunActive {
  width: 68px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
}
.buttonActive {
  width: 68px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
}
.modal-from-item {
  margin-left: 20px;
}
::v-deep .el-dialog .el-dialog__header {
  padding: 20px 20px 0 20px;
}
::v-deep .el-dialog .el-dialog__body {
  padding: 16px 20px 20px 20px;
}
::v-deep .el-form .el-form-item {
  margin-bottom: 16px !important;
  margin-right: 0 !important;
}
::v-deep .el-dialog .el-dialog__headerbtn {
  right: 20px;
  top: 25px;
}
::v-deep .el-button {
  margin: 0 0 0 20px !important;
}
</style>
<style lang="scss">
.addFreeLancherModal {
  .el-dialog__title {
    line-height: 24px;
    font-size: 21px;
    color: #303133;
  }
  .el-button + .el-button {
    margin: 14px 0 0 20px;
  }
  .el-select {
    width: 100%;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}
</style>
