<template>
  <div class="Adjustment">
    <div v-if="showButtonCall" style="text-align: right; margin-bottom: 12px">
      <el-button type="primary" @click="addNoHcRow" :loading="addHcLoading"
        >增加一行无HC调整</el-button
      >
    </div>

    <vxe-table
      show-header-overflow
      :height="tableHeight"
      ref="xTable4"
      border="none"
      class="mytable-footer"
      show-footer
      stripe
      :loading="loading"
      size="mini"
      :edit-config="{ trigger: 'dblclick', mode: 'cell' }"
      :footer-method="footerMethod"
      :footer-cell-class-name="footerCellClassName2"
      :span-method="mergeRowMethod"
      :data="tableData"
    >
      <vxe-column width="150" field="chargeToBp" title="To HRBP">
        <template #default="{ row }">
          <el-popover
            popper-class="adjustmentPopover"
            placement="bottom"
            width="300"
            v-model="row.popoverDisplay"
            trigger="manual"
          >
            <div class="popoverTop">
              <el-radio-group
                :disabled="disbled"
                @change="changeHrbpRadio(row)"
                v-model="row.selectHrbpType"
              >
                <el-radio label="selectHrbp">选择HRBP</el-radio>
                <el-radio label="other">其它</el-radio>
              </el-radio-group>
            </div>
            <div class="popoverForm">
              <el-form
                v-if="row.selectHrbpType === 'selectHrbp'"
                label-position="right"
                :disabled="disbled"
                label-width="120px"
                :model="row.chargeToBpData"
              >
                <el-form-item label="Assigment/project（Level2）">
                  <el-select
                    clearable
                    v-model="row.chargeToBpData.assigmentProjectLevel2"
                    filterable
                    placeholder="请选择"
                    class="industry-selector"
                  >
                    <el-option
                      v-for="item in row.assigmentProjectLevel2List"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value + ''"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="HFM Code">
                  <el-select
                    clearable
                    v-model="row.chargeToBpData.hfmCode"
                    filterable
                    placeholder="请选择"
                    class="industry-selector"
                  >
                    <el-option
                      v-for="item in row.hfmCodeList"
                      :key="item.label"
                      :label="item.value"
                      :value="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="HRBP Level3">
                  <!--                  <el-select-->
                  <!--                    clearable-->
                  <!--                    v-model="row.chargeToBpData.hrbpLevel3"-->
                  <!--                    filterable-->
                  <!--                    placeholder="请选择"-->
                  <!--                    class="industry-selector"-->
                  <!--                  >-->
                  <!--                    <el-option-->
                  <!--                      v-for="item in row.hrbpLevel3List"-->
                  <!--                      :key="item.label"-->
                  <!--                      :label="item.value"-->
                  <!--                      :value="item.label"-->
                  <!--                    ></el-option>-->
                  <!--                  </el-select>-->
                  <!--                  <el-input v-model="row.chargeToBpData.hrbpLevel3"> </el-input>-->
                  <el-input
                    :readonly="true"
                    v-model="row.chargeToBpData.hrbpLevel3"
                    @click.native.stop="selectPersonal('HRBP', row)"
                    placeholder="请选择"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
              <div
                class="popoverForm__tip"
                v-if="row.selectHrbpType === 'other'"
              >
                用于其它无法选择到具体HRBP情况下的处理；
                <br />
                <br />
                选择“其它”，TO HRBP字段会默认填充为“其它”，HFM
                Code字段会填充为Other；
              </div>
            </div>
            <div class="popoverButton">
              <el-button @click="row.popoverDisplay = false">关闭</el-button>
              <!--              <el-button type="primary" @click="popoverHRBPButton(row)"-->
              <!--                >确定</el-button-->
              <!--              >-->
            </div>
            <el-input
              slot="reference"
              :disabled="disbled"
              suffix-icon="el-icon-user"
              @click.native="openPop(row)"
              v-model="row.chargeToBpInputData"
            >
            </el-input>
          </el-popover>
        </template>
      </vxe-column>
      <vxe-column width="150" field="description" title="Description">
        <template #default="{ row }">
          <el-input
            type="textarea"
            autosize
            :disabled="disbled"
            placeholder="请输入内容"
            v-model="row.descriptionData"
          >
          </el-input>
        </template>
      </vxe-column>
      <vxe-column
        v-for="item in columnCutList"
        :field="item.key"
        :title="item.name"
        :visible="item.visible"
        :width="item.width"
      >
        <template v-if="item.fold" #header>
          <span>{{ item.name }}</span>
          <i
            style="cursor: pointer"
            :class="
              item.collapsable
                ? 'vxe-icon-square-minus'
                : 'vxe-icon-square-plus'
            "
            @click="item.collapsableEvent(item, $refs.xTable4)"
          ></i>
        </template>
      </vxe-column>
      <vxe-column width="180" field="functionSelect" title="方式">
        <template #default="{ row, rowIndex }">
          <div style="display: flex">
            <el-select
              clearable
              style="width: 85px"
              :disabled="disbled"
              v-model="row.functionSelectData"
              filterable
              @change="functionSelectChange(row)"
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in row.functionSelectList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-button
              v-if="rowIndex === 0 && !disbled"
              type="text"
              @click="downReturn"
              >向下带入</el-button
            >
          </div>
        </template>
      </vxe-column>
      <vxe-column width="150" field="chargeType" title="Cost / HC" />
      <vxe-column width="150" field="total" title="Total">
        <template #default="{ row }">
          <span v-if="row.chargeType === 'Cost'">{{
            countAmount(row, row.chargeType) || 0
          }}</span>
          <span v-if="row.chargeType === 'HC'">{{
            countAmount(row, row.chargeType) || 0
          }}</span>
        </template>
      </vxe-column>
      <vxe-column
        v-for="item in columnList"
        :field="item.key"
        :title="item.name"
        :visible="item.visible"
        :width="item.width"
        :edit-render="{}"
      >
        <template #edit="{ row }">
          <div v-if="row.functionSelectData === 'inputNumber'">
            <vxe-input
              v-model="row[item.key]"
              :disabled="disbled"
              @change="updateTableDataInput(row, item)"
              type="text"
              placeholder="请输入数值"
            ></vxe-input>
          </div>
          <div v-else style="display: flex" class="ProportionInput">
            <vxe-input
              v-model="row[item.key]"
              type="number"
              :disabled="disbled"
              @change="updateTableDataInput(row, item)"
              :min="-100"
              :max="100"
              placeholder="请输入数值"
            >
              <template #suffix>
                <svg-icon icon="baifenbi"></svg-icon>
              </template>
            </vxe-input>
            <div>
              {{ countProportion(row, item) }}
            </div>
          </div>
        </template>
        <template #default="{ row }">
          <div
            v-if="row.functionSelectData === 'inputProportion'"
            style="display: flex"
            class="ProportionInput"
          >
            <vxe-input
              v-model="row[item.key]"
              type="number"
              @change="updateTableDataInput(row, item)"
              :min="-100"
              :max="100"
              placeholder="请输入数值"
            >
              <template #suffix>
                <svg-icon icon="baifenbi"></svg-icon>
              </template>
            </vxe-input>
            <div>
              {{ countProportion(row, item) }}
            </div>
          </div>
          <div v-else>
            {{ row[item.key] }}
          </div>
        </template>
      </vxe-column>
      <vxe-column
        width="60"
        field="operate"
        align="center"
        fixed="right"
        title="操作"
        v-if="showButtonCall && !disbled"
      >
        <template #default="{ row }">
          <el-popconfirm @confirm="deleteChargeItem(row)" title="确定删除吗？">
            <vxe-button slot="reference" type="text">删除</vxe-button>
          </el-popconfirm>
        </template>
      </vxe-column>
    </vxe-table>
    <PersonnelSelectionDialog
      ref="PersonnelSelectionDialogRef"
      @onConfirm="onConfirm"
    />
  </div>
</template>
<script>
import {
  AdjustCutColumn,
  AdjustmentColumn
} from '@/views/salaryManagement/components/modal/ChargeOutDialog/AdjustmentColumn'
import { camelize, check, typeRange } from '@/utils/util'
import { demoData } from '@/store/modules/ChargeOut'
import PersonnelSelectionDialog from '@/components/PersonnelSelectionDialog/PersonnelSelectionDialog.vue'
import { gethfmCodes } from '@/api/pctrakerop131'
import { getAssigmentProjectLevel2 } from '@/api/personnelRequisition'
export default {
  name: 'Adjustment',
  components: {
    PersonnelSelectionDialog
  },
  data() {
    return {
      columnList: AdjustmentColumn,
      columnCutList: AdjustCutColumn,
      sumNames: [
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'dec',
        'nov'
      ],
      addHcLoading: false,
      tableData: [],
      loading: false,
      tableHeight: 200,
      le3Id: '',
      showButtonCall: true
    }
  },
  computed: {
    disbled() {
      return this.$store.state.ChargeOut.type === 'chat'
    }
  },
  created() {
    this.tableData = this.$store.state.ChargeOut.tableData
    console.log(this.tableData);
  },
  mounted() {
    let className = document.getElementsByClassName(
      'ChargeOut__content__rightComponent'
    )
    if (className.length) {
      this.tableHeight = className[0].clientHeight - 100
    }
  },
  methods: {
    updateTableDataInput(row, item) {
      const $table = this.$refs.xTable4
      this.countProportion(row, item)
      this.countAmount(row, row.chargeType)
      $table.updateFooter()
      $table.refreshColumn()
    },
    changCall() {
      this.showButtonCall = false
    },
    footerCellClassName2({ $rowIndex, column, columnIndex }) {
      // if (columnIndex === 0) {
      //   if ($rowIndex === 0) {
      //     return 'col-width'
      //   } else {
      //     return 'col-width'
      //   }
      // }
      return 'col-width'
    },
    openPop(row) {
      row.popoverDisplay = true
    },
    selectPersonal(val3, row) {
      this.le3Id = row.id
      this.$refs.PersonnelSelectionDialogRef.show({
        type: val3,
        radio: true
      })
    },
    functionSelectChange(row) {
      let functionSelectData = row.functionSelectData
      let hcInformationId = row.hcInformationId
      if (functionSelectData) {
        this.tableData.forEach((item) => {
          if (item.hcInformationId === hcInformationId) {
            item.functionSelectData = functionSelectData
          }
        })
      }
      this.$refs.xTable4.updateFooter()
    },
    updateTableData(val) {
      this.tableData = val
    },
    meanNum(list, field, flag = 'Cost') {
      let count = 0
      list.forEach((item) => {
        if (
          item.chargeType === flag &&
          item.functionSelectData === 'inputNumber'
        ) {
          count += Number(item[field]) || 0
        } else if (
          item.chargeType === flag &&
          item.functionSelectData === 'inputProportion'
        ) {
          if (field === 'total') {
            count += Number(item[`total`]) || 0
          } else {
            count +=
              Number(item[`total${camelize(flag)}Charge${camelize(field)}`]) ||
              0
          }
        }
      })
      return count.toFixed(2)
    },
    footerMethod({ columns, data }) {
      let sumNames = JSON.parse(JSON.stringify(this.sumNames))
      sumNames.push('total')
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return 'Total Intercompany Recharge (Cost)'
          }

          if (sumNames.includes(column.property)) {
            return this.meanNum(data, column.property, 'Cost')
          }
          return null
        }),
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return 'Total Intercompany Recharge (HC)'
          }
          if (sumNames.includes(column.property)) {
            return this.meanNum(data, column.property, 'HC')
          }
          return null
        })
      ]
    },
    popoverHRBPButton(row) {
      row.popoverDisplay = false
    },
    changeHrbpRadio(row) {
      if (row.selectHrbpType && row.selectHrbpType === 'other') {
        row.hfmCode = 'other'
        row.chargeToBpInputData = '其它'
      } else {
          row.hfmCode = ''
          row.chargeToBpInputData = ''
      }
    },
    onConfirm(val) {
      if (val && val.length) {
        this.tableData.forEach((item) => {
          if (item.id === this.le3Id) {
            item.chargeToBpData.hrbpLevel3 = val[0].userName
            item.chargeToBpData.hrbpLevel3Id = val[0].id
            item.chargeToBpInputData = val[0].userName
          }
        })
      }
    },
    // 通用行合并函数（将相同多列数据合并为一行）
    mergeRowMethod({ row, _rowIndex, column, visibleData }) {
      const fields = [
        'chargeToBp',
        'description',
        'englishName',
        'functionSelect',
        'operate'
      ]
      this.columnCutList.forEach((item) => {
        fields.push(item.key)
      })
      const cellValue = row[column.property]
      if (cellValue && fields.includes(column.property)) {
        const prevRow = visibleData[_rowIndex - 1]
        let nextRow = visibleData[_rowIndex + 1]
        if (prevRow && prevRow[column.property] === cellValue) {
          return { rowspan: 0, colspan: 0 }
        } else {
          let countRowspan = 1
          while (nextRow && nextRow[column.property] === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex]
          }
          if (countRowspan > 1) {
            return { rowspan: countRowspan, colspan: 1 }
          }
        }
      }
    },
    downReturn() {
      this.tableData.forEach((item, index) => {
        if (index !== 0) {
          item.functionSelectData = this.tableData[0].functionSelectData
        }
      })
    },
    deleteChargeItem(row) {
      let hcInformationId = row.hcInformationId
      if (hcInformationId) {
        this.tableData = this.tableData.filter(
          (item) => item.hcInformationId !== hcInformationId
        )
      }
      this.$store.dispatch('ChargeOut/deleteSelectVal', hcInformationId)
    },
    addNoHcRow() {
      this.addHcLoading = true
      let CostData = JSON.parse(JSON.stringify(demoData))
      let id = Math.floor(Math.random() * (10000 - 1)) + 1
      CostData.id = id
      CostData.hcInformationId = id
      CostData.chargeToBp = `chargeToBp${id}`
      CostData.description = `description${id}`
      CostData.popoverDisplay = false
      CostData.englishName = '-'
      CostData.noHc = true
      CostData.isHc = false
      AdjustCutColumn.forEach((item) => {
        CostData[item.key] = '-'
      })
      CostData.functionSelect = `functionSelect${id}`
      CostData.operate = `operate${id}`
      this.sumNames.forEach((itemName) => {
        CostData[`hc${this.titleCase(itemName)}`] = 1
        CostData[`totalCost${this.titleCase(itemName)}`] = 1
      })
      gethfmCodes()
        .then((res) => {
          CostData.hfmCodeList = res.data
          getAssigmentProjectLevel2()
            .then((res1) => {
              this.addHcLoading = false
              CostData.assigmentProjectLevel2List = res1.data
              let a = JSON.parse(JSON.stringify(CostData))
              let b = JSON.parse(JSON.stringify(CostData))
              a.chargeType = 'Cost'
              this.tableData.push(a)
              b.chargeType = 'HC'
              this.tableData.push(b)
            })
            .catch(() => {
              this.addHcLoading = true
            })
        })
        .catch(() => {
          this.addHcLoading = true
        })
    },
    titleCase(str) {
      return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase()
    },
    countProportion(row, item) {
      if (row.chargeType === 'Cost') {
        let value = Number(row[item.key])
        let itemKey = this.titleCase(item.key)
        let totalCost = Number(row[`totalCost${itemKey}`])
        let propData = ((value / 100) * totalCost).toFixed(2)
        row[`totalCostCharge${itemKey}`] = propData
        return propData
      } else if (row.chargeType === 'HC') {
        let value = Number(row[item.key])
        let itemKey = this.titleCase(item.key)
        let totalCost = Number(row[`hc${itemKey}`])
        let propData = ((value / 100) * totalCost).toFixed(2)
        row[`totalHcCharge${itemKey}`] = propData
        return propData
      }
    },
    countAmount(row, flag = '') {
      if (row.functionSelectData === 'inputNumber') {
        let sum = 0
        this.sumNames.forEach((item) => {
          if (row.chargeType === flag) {
            sum += Number(row[item])
          }
        })
        row.total = Number(sum).toFixed(2)
        return Number(sum).toFixed(2)
      } else if (row.functionSelectData === 'inputProportion') {
        let sum = 0
        this.sumNames.forEach((item) => {
          if (flag === 'Cost') {
            sum += Number(row[`totalCostCharge${this.titleCase(item)}`])
          } else if (flag === 'HC') {
            sum += Number(row[`totalHcCharge${this.titleCase(item)}`])
          }
        })
        if (sum && check(sum) === typeRange.number) {
          row.total = Number(sum).toFixed(2)
          return Number(sum).toFixed(2)
        } else {
          row.total = Number(sum).toFixed(2)
          return Number(0).toFixed(2)
        }
      } else {
        row.total = Number(0).toFixed(2)
        return Number(0).toFixed(2)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.Adjustment {
  width: 100%;
}
</style>
<style lang="scss">
.mytable-footer .col-width {
  line-height: 12px;
}
.ProportionInput {
  justify-content: space-between;
  overflow: hidden;
  .vxe-input {
    width: 65px;
  }
}
.adjustmentPopover {
  padding: 0;
  .popoverTop {
    padding: 16px;
    border-bottom: 1px solid #ebeef5;
  }
  .popoverForm {
    padding: 16px;
    .el-form-item__label {
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #868686;
    }
    &__tip {
      font-size: 13px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #868686;
    }
  }
  .popoverButton {
    padding: 16px;
    text-align: right;
  }
  .col-width {
    width: 200px;
  }
}
</style>
