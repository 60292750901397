<template>
  <div class="addIcChargeModal">
    <el-dialog
      :visible.sync="dialogVisible"
      width="900px"
      :title="modalTitle"
      @close="close"
    >
      <div style="text-align: center; font-size: 20px">
        <div class="inputForm">
          <el-form>
            <div class="flexBetw">
              <div class="flex-1">
                <el-form-item label="Assigment/project(Level2)">
                  <el-select
                    clearable
                    v-model="formInline.assigmentProjectLevel2"
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in level2s"
                      :key="item.orgCode"
                      :label="item.orgName"
                      :value="item.orgName"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="flex-1">
                <el-form-item label="HFM Code" class="modal-from-item">
                  <el-select
                    clearable
                    v-model="formInline.hfmCode"
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in hfmCodes"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="flex-1">
                <el-form-item
                  label="HRBP Level3"
                  prop="creator"
                  class="modal-from-item"
                >
                  <el-input
                    :readonly="!formStatus"
                    :disabled="formStatus"
                    v-model="formInline.creatorName"
                    @click.native.stop="
                      selectPersonal('creatorName', 'chargeToBp', 'HRBP')
                    "
                    placeholder="请选择"
                  >
                  </el-input>
                </el-form-item>
              </div>
            </div>
            <el-form-item label="Description">
              <el-input
                v-model="formInline.description"
                type="textarea"
              ></el-input>
            </el-form-item>
            <div class="flexBetw">
              <div class="flex-1">
                <el-form-item>
                  <p style="text-align: left">Cost</p>
                </el-form-item>

                <el-form-item>
                  <el-col :span="2">
                    <p>Jan</p>
                  </el-col>
                  <el-col :span="9">
                    <el-input
                      v-model="formInline.janCost"
                      @input="$event?formInline.janCost = $event:formInline.janCost = 0"
                      type="number"
                    ></el-input>
                  </el-col>
                  <el-col :span="2" :offset="1">
                    <p>Jul</p>
                  </el-col>
                  <el-col :span="9">
                    <el-input
                      v-model="formInline.julCost"
                      @input="$event?formInline.julCost = $event:formInline.julCost = 0"
                      type="number"
                    ></el-input>
                  </el-col>
                </el-form-item>

                <el-form-item>
                  <el-col :span="2">
                    <p>Feb</p>
                  </el-col>
                  <el-col :span="9">
                    <el-input
                      v-model="formInline.febCost"
                      @input="$event?formInline.febCost = $event:formInline.febCost = 0"
                      type="number"

                    ></el-input>
                  </el-col>
                  <el-col :span="2" :offset="1">
                    <p>Aug</p>
                  </el-col>
                  <el-col :span="9">
                    <el-input
                      v-model="formInline.augCost"
                      @input="$event?formInline.augCost = $event:formInline.augCost = 0"
                      type="number"
                    ></el-input>
                  </el-col>
                </el-form-item>

                <el-form-item>
                  <el-col :span="2">
                    <p>Mar</p>
                  </el-col>
                  <el-col :span="9">
                    <el-input
                      v-model="formInline.marCost"
                      @input="$event?formInline.marCost = $event:formInline.marCost = 0"
                      type="number"
                    ></el-input>
                  </el-col>
                  <el-col :span="2" :offset="1">
                    <p>Sep</p>
                  </el-col>
                  <el-col :span="9">
                    <el-input
                      v-model="formInline.sepCost"
                      @input="$event?formInline.sepCost = $event:formInline.sepCost = 0"
                      type="number"
                    ></el-input>
                  </el-col>
                </el-form-item>

                <el-form-item>
                  <el-col :span="2">
                    <p>Apr</p>
                  </el-col>
                  <el-col :span="9">
                    <el-input
                      v-model="formInline.aprCost"
                      @input="$event?formInline.aprCost = $event:formInline.aprCost = 0"
                      type="number"
                    ></el-input>
                  </el-col>
                  <el-col :span="2" :offset="1">
                    <p>Oct</p>
                  </el-col>
                  <el-col :span="9">
                    <el-input
                      v-model="formInline.octCost"
                      @input="$event?formInline.octCost = $event:formInline.octCost = 0"
                      type="number"
                    ></el-input>
                  </el-col>
                </el-form-item>

                <el-form-item>
                  <el-col :span="2">
                    <p>May</p>
                  </el-col>
                  <el-col :span="9">
                    <el-input
                      v-model="formInline.mayCost"
                      @input="$event?formInline.mayCost = $event:formInline.mayCost = 0"
                      type="number"
                    ></el-input>
                  </el-col>
                  <el-col :span="2" :offset="1">
                    <p>Nov</p>
                  </el-col>
                  <el-col :span="9">
                    <el-input
                      v-model="formInline.novCost"
                      @input="$event?formInline.novCost = $event:formInline.novCost = 0"
                      type="number"
                    ></el-input>
                  </el-col>
                </el-form-item>

                <el-form-item>
                  <el-col :span="2">
                    <p>Jun</p>
                  </el-col>
                  <el-col :span="9">
                    <el-input
                      v-model="formInline.junCost"
                      @input="$event?formInline.junCost = $event:formInline.junCost = 0"
                      type="number"
                    ></el-input>
                  </el-col>
                  <el-col :span="2" :offset="1">
                    <p>Dec</p>
                  </el-col>
                  <el-col :span="9">
                    <el-input
                      v-model="formInline.decCost"
                      @input="$event?formInline.decCost = $event:formInline.decCost = 0"
                      type="number"
                    ></el-input>
                  </el-col>
                </el-form-item>
              </div>

              <div class="flex-1 align-items-end">
                <el-form-item>
                  <p style="text-align: left; margin-left: 25px">HC</p>
                </el-form-item>
                <el-form-item>
                  <el-row type="flex" justify="end">
                    <el-col :span="2">
                      <p>Jan</p>
                    </el-col>
                    <el-col :span="9">
                      <el-input
                        v-model="formInline.janHc"
                        @input="$event?formInline.janHc = $event:formInline.janHc = 0"
                        type="number"
                      ></el-input>
                    </el-col>
                    <el-col :span="2" :offset="1">
                      <p>Jul</p>
                    </el-col>
                    <el-col :span="9">
                      <el-input
                        v-model="formInline.julHc"
                        @input="$event?formInline.julHc = $event:formInline.julHc = 0"
                        type="number"
                      ></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>

                <el-form-item>
                  <el-row type="flex" justify="end">
                    <el-col :span="2">
                      <p>Feb</p>
                    </el-col>
                    <el-col :span="9">
                      <el-input
                        v-model="formInline.febHc"
                        @input="$event?formInline.febHc = $event:formInline.febHc = 0"
                        type="number"
                      ></el-input>
                    </el-col>
                    <el-col :span="2" :offset="1">
                      <p>Aug</p>
                    </el-col>
                    <el-col :span="9">
                      <el-input
                        v-model="formInline.augHc"
                        @input="$event?formInline.augHc = $event:formInline.augHc = 0"
                        type="number"
                      ></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>

                <el-form-item>
                  <el-row type="flex" justify="end">
                    <el-col :span="2">
                      <p>Mar</p>
                    </el-col>
                    <el-col :span="9">
                      <el-input
                        v-model="formInline.marHc"
                        @input="$event?formInline.marHc = $event:formInline.marHc = 0"
                        type="number"
                      ></el-input>
                    </el-col>
                    <el-col :span="2" :offset="1">
                      <p>Sep</p>
                    </el-col>
                    <el-col :span="9">
                      <el-input
                        v-model="formInline.sepHc"
                        @input="$event?formInline.sepHc = $event:formInline.sepHc = 0"
                        type="number"
                      ></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>

                <el-form-item>
                  <el-row type="flex" justify="end">
                    <el-col :span="2">
                      <p>Apr</p>
                    </el-col>
                    <el-col :span="9">
                      <el-input
                        v-model="formInline.aprHc"
                        @input="$event?formInline.aprHc = $event:formInline.aprHc = 0"
                        type="number"
                      ></el-input>
                    </el-col>
                    <el-col :span="2" :offset="1">
                      <p>Oct</p>
                    </el-col>
                    <el-col :span="9">
                      <el-input
                        v-model="formInline.octHc"
                        @input="$event?formInline.octHc = $event:formInline.octHc = 0"
                        type="number"
                      ></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>

                <el-form-item>
                  <el-row type="flex" justify="end">
                    <el-col :span="2">
                      <p>May</p>
                    </el-col>
                    <el-col :span="9">
                      <el-input
                        v-model="formInline.mayHc"
                        @input="$event?formInline.mayHc = $event:formInline.mayHc = 0"
                        type="number"
                      ></el-input>
                    </el-col>
                    <el-col :span="2" :offset="1">
                      <p>Nov</p>
                    </el-col>
                    <el-col :span="9">
                      <el-input
                        v-model="formInline.novHc"
                        @input="$event?formInline.novHc = $event:formInline.novHc = 0"
                        type="number"
                      ></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>

                <el-form-item>
                  <el-row type="flex" justify="end">
                    <el-col :span="2">
                      <p>Jun</p>
                    </el-col>
                    <el-col :span="9">
                      <el-input
                        v-model="formInline.junHc"
                        @input="$event?formInline.junHc = $event:formInline.junHc = 0"
                        type="number"
                      ></el-input>
                    </el-col>
                    <el-col :span="2" :offset="1">
                      <p>Dec</p>
                    </el-col>
                    <el-col :span="9">
                      <el-input
                        v-model="formInline.decHc"
                        @input="$event?formInline.decHc = $event:formInline.decHc = 0"
                        type="number"
                      ></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
      </div>
      <div style="text-align: right; margin-top: 14px">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="addChargeRecord">确 定</el-button>
        <div class="clear"></div>
      </div>
      <PersonnelSelectionDialog
        ref="PersonnelSelectionDialogRef"
        @onConfirm="onConfirm"
      />
    </el-dialog>
  </div>
</template>

<script>
const { Vuex } = $PCommon

import {
  gethfmCodes,
  getLevel2Orgs,
  addRecord,
  updateRecord
} from '@/api/pctrakerop131'
import PersonnelSelectionDialog from '@/components/PersonnelSelectionDialog/PersonnelSelectionDialog'
import { on } from 'events'

export default {
  name: '',
  mixins: [$PCommon.TableMixin],
  components: {
    PersonnelSelectionDialog
  },
  props: {
    periods: {
      type: Object
    },
    versionId: {
      type: Number
    },
    editType: {
      type: String
    },
    isSummary: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      modalTitle: '',
      level2s: [],
      hfmCodes: [],
      formInline: {
        id: '',
        versionId: '',
        summaryVersionId: '',
        creatorName: '',
        chargeToBp: '',
        assigmentProjectLevel2: '',
        hfmCode: '',
        description: '',
        janCost: '',
        janHc: '',
        febCost: '',
        febHc: '',
        marCost: '',
        marHc: '',
        aprCost: '',
        aprHc: '',
        mayCost: '',
        mayHc: '',
        junCost: '',
        junHc: '',
        julCost: '',
        julHc: '',
        augCost: '',
        augHc: '',
        sepCost: '',
        sepHc: '',
        octCost: '',
        octHc: '',
        novCost: '',
        novHc: '',
        decCost: '',
        decHc: ''
      },
      formInline2: {
        creatorName: '',
        chargeToBp: ''
      },
      formStatus: false,
      dialogVisible: true,
      targetObj: '',
      toObj: ''
    }
  },
  computed: {},

  created() {
    this.modalTitle = '调整 IC Charge'
    this.formInline.versionId = this.versionId

    const data = JSON.parse(JSON.stringify(this.periods))
    this.formInline.assigmentProjectLevel2 = data.assigmentProjectLevel2
    this.formInline.hfmCode = data.hfmCode
    this.formInline.description = data.description
    this.formInline.janCost = data.janCost
    this.formInline.janHc = data.janHc
    this.formInline.febCost = data.febCost
    this.formInline.febHc = data.febHc
    this.formInline.marCost = data.marCost
    this.formInline.marHc = data.marHc
    this.formInline.aprCost = data.aprCost
    this.formInline.aprHc = data.aprHc
    this.formInline.mayCost = data.mayCost
    this.formInline.mayHc = data.mayHc
    this.formInline.junCost = data.junCost
    this.formInline.junHc = data.junHc
    this.formInline.julCost = data.julCost
    this.formInline.julHc = data.julHc
    this.formInline.augCost = data.augCost
    this.formInline.augHc = data.augHc
    this.formInline.sepCost = data.sepCost
    this.formInline.sepHc = data.sepHc
    this.formInline.octCost = data.octCost
    this.formInline.octHc = data.octHc
    this.formInline.novCost = data.novCost
    this.formInline.novHc = data.novHc
    this.formInline.decCost = data.decCost
    this.formInline.decHc = data.decHc
    this.formInline.id = data.id
    this.formInline.creatorName = data.toHrbpName
    this.formInline.chargeToBp = data.chargeToBp

    gethfmCodes().then((res) => {
      this.hfmCodes = res.data
    })

    getLevel2Orgs().then((res) => {
      this.level2s = res.data
    })
  },
  methods: {
    initEditData() {},

    addChargeRecord() {
      if (!this.formInline.hfmCode) {
        this.$message.error('HFM Code 不能为空')
        return
      }
      if (!this.formInline.assigmentProjectLevel2) {
        this.$message.error('Assigment/project(Level2) 不能为空')
        return
      }
      if (!this.formInline.creatorName) {
        this.$message.error('HRBP Level3 不能为空')
        return
      }
      if (this.isSummary) {
        this.formInline.summaryVersionId = this.formInline.versionId
      }
      if (this.editType == 'new') {
        addRecord(this.formInline).then((res) => {
          this.dialogVisible = false
          this.$emit('closeModal')
        })
      }

      if (this.editType == 'edit') {
        updateRecord(this.formInline).then((res) => {
          this.dialogVisible = false
          this.$emit('closeModal')
        })
      }
    },

    close() {
      this.dialogVisible = false
      this.$emit('closeModal')
    },

    selectPersonal(val1, val2, val3) {
      // 'creatorName', 'chargeToBp', 'HRBP'
      if (!this.formStatus) {
        this.targetObj = val1
        this.toObj = val2
        this.$refs.PersonnelSelectionDialogRef.show({
          type: val3
        })
      }
    },

    onConfirm(val) {
      if (val && val.length) {
        let data = val[0]
        this.formInline[this.toObj] = data.id
        this.formInline[this.targetObj] = data.userName
      }
    }
  },
  beforeDestroy() {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
.inputForm {
  p {
    margin: 0;
    text-align: center;
  }
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.align-items-end {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 48px;
}
.el-col p {
  color: #868686;
}
.modal-from-item {
  margin-left: 20px;
}
::v-deep .el-form .el-form-item {
  margin-bottom: 16px !important;
}
::v-deep .el-form .el-form-item {
  margin-right: 0 !important;
}
::v-deep .el-dialog__header {
  padding: 20px;
}
::v-deep .el-button {
  margin-left: 16px !important;
  margin-right: 0 !important;
}
::v-deep .el-dialog .el-dialog__headerbtn {
  right: 20px;
  top: 25px;
}
</style>

<style lang="scss">
.addIcChargeModal {
  .el-dialog__title {
    font-size: 21px;
    color: #303133;
    width: 144px;
    height: 29px;
    font-weight: 400;
    line-height: 29px;
  }
  .el-dialog .el-dialog__body {
    padding: 0 20px 20px 20px;
  }
  .el-button + .el-button {
    margin-left: 12px;
  }
  .el-select {
    width: 100%;
  }
}
</style>
